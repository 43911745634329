import React, { useState, useEffect } from 'react'
import rehypeReact from "rehype-react"
import styles from './article-template.module.css'
import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import TimeAgo from 'react-timeago'
import WidgetMostpopular from '../components/widgets/widget-mostpopular/widget-mostpopular'
import WidgetNewest from '../components/widgets/widget-newest/widget-newest'
import AdSense from 'react-adsense'
import AdInContent from '../components/ad-incontent'
import axios from 'axios'

export default function Template({data}){
  const article = data.markdownRemark
  const [count, setCount] = useState(0)

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { "ad-incontent": AdInContent }
  }).Compiler

  const twitterHref = (title) => {
    return 'https://twitter.com/intent/tweet?text=' + title + ' ' + '@spot_code'
  }

  const handleScroll = (event) => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

    var element = document.querySelector('#test');
    var bottom = top + element.offsetHeight;
    if (top < 870) {
      element.className = styles.test
      element.style.top = '0px';
    } else if (bottom > document.querySelector('.bottom').offsetTop + 60){
      element.className = styles.testAbsolute
      element.style.top = document.querySelector('.bottom').offsetTop - element.offsetHeight -20 +'px';
    }  else {
      element.className = styles.testFixed
      element.style.top = '3%';
    }
  }

  //Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    const scriptGrid = document.createElement("script");
    scriptGrid.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=10fcdcc8-f6c1-46b2-9994-ca59baff2d5a";
    scriptGrid.async = true;
    document.body.appendChild(scriptGrid);

    getGoogleAnalyticsData()
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  async function getGoogleAnalyticsData(){
    try {
      const res = await axios.get('https://codespot-reporting-api.herokuapp.com/api');
      Promise.resolve(res.data.result.data.rows).then(values => {
        setCount(values);
      })
    } catch (err) {
      console.error(err);
    }
  }

  return(
    <Layout>
      <Seo title={article.frontmatter.title} image={article.frontmatter.image} article={true}/>
      <div className='row'>
        <div className='col-lg-8'>
          <div className='ad-leaderboard-root'>
            <AdSense.Google
              className='ad-leaderboard'
              client='ca-pub-2585619948311874'
              slot='9722845538'
              style={{ display: 'inline-block', height: 90, width:728}} 
              format=''               
            />
          </div>
          <h1 style={{margin: '0 0 40px 0'}}>{article.frontmatter.title}</h1>
          <div>{renderAst(article.htmlAst)}</div>
          <hr/>
          <div className="bottom"></div>
          <a className="twitter-share-button" data-size='large' href={twitterHref(article.frontmatter.title)}>Tweet</a>
          <br/>
          {/* <div className='row'>
            <div className='col'>
              <div className={styles.topRatedText}>Sponsored:</div>
            </div>
          </div> */}
          <div className='row'>
            <div className='col-lg'>
            <div id="amzn-assoc-ad-10fcdcc8-f6c1-46b2-9994-ca59baff2d5a"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=10fcdcc8-f6c1-46b2-9994-ca59baff2d5a"></script>
            </div>
            {/* <div rel="sponsored" className='col-lg border' style={{padding: 0, margin:'4px'}}>
              <a href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.625204&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-web-developer-bootcamp%2F"><img className={styles.udemyImg} alt="The Web Developer Bootcamp" src="https://img-a.udemycdn.com/course/480x270/625204_436a_2.jpg"/>
                <span>
                  <div className={styles.udemyTitle}>The Web Developer Bootcamp</div>
                  <div className={styles.udemyText}>The only course you need to learn web development - HTML, CSS, JS, Node, and More!</div>
                </span>
              </a><img border={0} width={1} height={1} src="https://ad.linksynergy.com/fs-bin/show?id=LFcSQV4ezac&bids=507388.625204&type=2&subid=0" />
            </div>
            <div className='col-lg border' style={{padding: 0, margin:'4px'}}>
              <a rel="sponsored" href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.851712&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-javascript-course%2F"><img className={styles.udemyImg} alt="The Complete JavaScript Course 2020: Build Real Projects!" src="https://i.udemycdn.com/course/480x270/851712_fc61_5.jpg"/>
                <span>
                  <div className={styles.udemyTitle}>The Complete JavaScript Course 2020: Build Real Projects!</div>
                  <div className={styles.udemyText}>Master JavaScript with the most complete course! Projects, challenges, quizzes, JavaScript ES6+, OOP, AJAX, Webpack</div>
                </span>
              </a><img border={0} width={1} height={1} src="https://ad.linksynergy.com/fs-bin/show?id=LFcSQV4ezac&bids=507388.851712&type=2&subid=0" />
            </div>
            <div rel="sponsored" className='col-lg border' style={{padding: 0, margin:'4px'}}>
              <a href="https://click.linksynergy.com/link?id=LFcSQV4ezac&offerid=507388.922484&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-nodejs-developer-course-2%2F"><img className={styles.udemyImg} alt="The Complete Node. js Developer Course (3rd Edition)" src="https://i.udemycdn.com/course/480x270/922484_52a1_8.jpg"/>
                <span>
                  <div className={styles.udemyTitle}>The Complete Node. js Developer Course (3rd Edition)</div>
                  <div className={styles.udemyText}>Learn Node. js by building real-world applications with Node, Express, MongoDB, Jest, and more!</div>
                </span>
              </a><img border={0} width={1} height={1} src="https://ad.linksynergy.com/fs-bin/show?id=LFcSQV4ezac&bids=507388.922484&type=2&subid=0" />
            </div> */}
          </div>
          <br/>
          <h3>{article.frontmatter.tags}</h3>
          <h4>Author: {article.frontmatter.author} | <TimeAgo date={article.frontmatter.date}/></h4>
        </div>
        <div id='article-sidebar' className='col-lg-4'>
          <div className={styles.sidebar}>
            <div className={styles.wrapperMostPopular}>
              <WidgetMostpopular mostPopular={count}/>
            </div>
            <WidgetNewest articles={data.allMarkdownRemark.edges} />
            <div id="test" className={styles.test}>
            <AdSense.Google
              client='ca-pub-2585619948311874'
              slot='4897069852'
              style={{ display: 'inline-block', height: 600, width:300}} 
              format=''               
            />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const articleQuery = graphql`
query BlogArticlePath($path: String!){
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }){
    edges{
      node{
        html
        id
        frontmatter{
          path
          title
          author
          category
          date
        }
      }
    }
  }
  markdownRemark(frontmatter: { path: { eq: $path } }){
    htmlAst
    frontmatter{
      path
      title
      author
      date
      image
      tags
    }
  }
}
`