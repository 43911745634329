import React from 'react'
import AdSense from 'react-adsense'
 
const AdInContent = () => {
  return(
    <AdSense.Google
      client='ca-pub-2585619948311874'
      slot='1597422437'
      style={{ display: 'block', textAlign: 'center'}} 
      layout='in-article'
      format='fluid'
    />
  )
}

export default AdInContent