import React from 'react'
import Link from 'gatsby-link'
import styles from '../widget-newest/widget-newest.module.css'

const WidgetNewest = (props) => (
  <div>
  <h3 className ={styles.widgetNewestTitle}>Newest</h3>
  <div className={styles.widgetNewest}>
    <ul className={styles.categotyList}>
      {props.articles.map((article, index) => (
        index < 5 ? <li key={index}><Link to={article.node.frontmatter.path} className={styles.newestArticle}>{article.node.frontmatter.title}</Link></li> : null
      ))}
    </ul>
  </div>
  </div>
)

export default WidgetNewest